import React from 'react';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';

const Currency: React.FC<{ value: number, className?: any }> = ({ value = 0, className }) => (
    <NumericFormat 
        className={classNames('', className)}
        value={value} 
        displayType={'text'} 
        thousandSeparator='.'
        decimalSeparator=','
        prefix={'$'} 
    />
);

export default Currency;