import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestArticulos } from '../../store/articulos/articulos.actions';
import { Familia, Subfamilia } from '../../classes/familia';
import Articulo from '../../classes/articulo';
import Button from '../../core/Button/Button';
import Cliente from '../../classes/cliente';
import Error from './../../components/Error/Error';
import Spinner from './../../core/Spinner/Spinner';
import ProductItem, { IProductItem } from './../ProductItem/ProductItem';
import qs from 'qs';

import './ProductList.scss';


const ProductList: React.FunctionComponent<any> = ({ location }) => {
    const { familia, subfamilia, filter } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { articulos, error, fetching, totalPages, currentPage } = useSelector((state: any) => state.articulosStore);

    const cliente: Cliente = useSelector((state: any) => state.authStore.user);
    const familias: Familia[] = useSelector((state: any) => state.familiasStore.familias);
    const listapre: number = cliente && cliente.LISTAPRE;
    const showPrice: boolean = cliente !== null;

    const dispatch = useDispatch();

    const fetchData = useCallback((page = 0) => {
        if (familia || subfamilia || filter) { 
            const params: any = familia ? { familia: Number(familia), subfamilia: Number(subfamilia) } : { filter };
            params.page = page;
            if (familia || filter) dispatch(requestArticulos(params));
        }

    }, [dispatch, filter, familia, subfamilia]);
    
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);

    const renderPagiantion = () => {
        const paginator: JSX.Element[] = [];
        
        for (let i = 1; i <= totalPages; i++) {
            const acceptedRange: boolean = Math.abs(currentPage - i) < 5;

            if (acceptedRange) {
                paginator.push(
                    <Button 
                        click={() => {
                            fetchData(i);
                            executeScroll();
                        }}
                        className={currentPage === i ? 'ActivePage' : 'PageButton'}
                        key={`p${i}`}>{i}
                    </Button>
                );
            }
        }

        if (totalPages > currentPage) {
            paginator.push(
                <Button 
                    click={() => {
                        fetchData(currentPage + 1);
                        executeScroll();
                    }}
                    className='PageButton nextPage'
                    key='nextPage'>Página siguiente
                </Button>
            );
        }

        if (paginator.length < 2) {
            return null;
        }
        return <>{paginator.map(el => el)}</>
    }

    const getProductPrice = (articulo: Articulo): number => {
        switch (listapre) {
            case 1: return articulo.PVENTA_1;
            case 2: return articulo.PVENTA_2;
            case 3: return articulo.PVENTA_3;
            case 4: return articulo.PVENTA_4;
            default: return articulo.PVENTA_1;
        };
    };

    if (error) {
        setTimeout(fetchData, 2000)
        return <Spinner>Buscando productos...</Spinner>;
    }

    if (fetching) {
        return <Spinner>Buscando productos...</Spinner>;
    }
    
    if (!Number(familia) && !Number(subfamilia) && !filter) {
        return (
            <div className="ProductGetStarted">
                <p>Busque productos o seleccione una familia de la columna.</p>
                <div className="separator"></div>
            </div>
        );
    }

    if (!articulos.length) {
        return <p>No se encontraron artículos para los filtros establecidos.</p>;
    }


    return (
        <div className="ProductList">
            <div className="row" ref={myRef}>
                {articulos.map((a: Articulo, index: number) => {                    
                    const familia: Familia|undefined = familias.find((f: Familia) => f.ID_FLIA === a.ID_FLIA);
                    const subfamilia = familia && familia.SUBFAMILIAS.find((s: Subfamilia) => s.ID_SUBF === a.ID_SUBF);
                    const price = getProductPrice(a);

                    if (price === 0) return null;

                    const product: IProductItem = {
                        codart: a.CODART,
                        desart: a.DESART,
                        familia: familia && familia.NFAMILIA,
                        subfamilia: subfamilia && subfamilia.NSUBF,
                        price,
                        imgUrl: a.FILE1,
                        showPrice,
                        unidad: a.UNIDAD,
                        nota: a.NOTA
                    };

                    return <ProductItem key={index} {...product} />
                })}
            </div>

            <div className="pagination">
                {renderPagiantion()}
            </div>
        </div>
    );
};

export default ProductList;
